/* eslint-disable @typescript-eslint/ban-ts-comment */
import { GraphQLClient } from "graphql-request"
import {
  type RequestMiddleware,
  type Response,
} from "graphql-request/build/esm/types"
import {
  checkUnauthorized,
  FLAG_DIET_COOKIE,
  graphqlClientMiddleware,
  graphqlClientMiddlewareV2,
  type TRequest,
} from "shared-utils"

import { signOut, signIn } from "@/authentication/authService"
import { GRAPHQL_URL } from "@/config/api"
import { AUTH } from "@/config/client"

const middleware: RequestMiddleware = (request) => {
  const resultMiddleware = FLAG_DIET_COOKIE
    ? graphqlClientMiddlewareV2({
        request: request as TRequest,
        signOut,
        app: "BUYER",
        baseUrl: AUTH.BASE_URL,
      })
    : graphqlClientMiddleware({
        request: request as TRequest,
        signOut,
        app: "BUYER",
        baseUrl: AUTH.BASE_URL,
      })
  return resultMiddleware
}

export const responseMiddleware: (
  response: Error | Response<unknown>
) => void = (response) => {
  if (checkUnauthorized(response)) {
    signIn("refreshToken")
  }
  return response
}

export const federatedGqlClient = new GraphQLClient(GRAPHQL_URL, {
  requestMiddleware: middleware,
  responseMiddleware: responseMiddleware,
  credentials: "include",
})
